define(['lodash', 'warmupUtils/pointers/pointerGeneratorsRegistry'], function (_, pointerGeneratorsRegistry) {
    'use strict';

    const type = 'partners';
    pointerGeneratorsRegistry.registerPointerType(type, _.constant(null), _.constant(true));
    function getIsPotentialPartnerPointer(getItemAt, cache) {
        return cache.getPointer('isPotentialPartner', type, [type]);
    }
    const getterFunctions = {
        getIsPotentialPartnerPointer
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions);
});
