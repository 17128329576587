define([
    'warmupUtils/pointers/pointerGeneratorsRegistry',
    'warmupUtils/pointers/pointersCache',
    'warmupUtils/pointers/DataAccessPointers',

    // Side effects
    'warmupUtils/pointers/aspectsPointers',
    'warmupUtils/pointers/componentPointers',
    'warmupUtils/pointers/componentStructurePointers',
    'warmupUtils/pointers/compsChangedByRelayoutPointers',
    'warmupUtils/pointers/dataPointers',
    'warmupUtils/pointers/svgShapePointers',
    'warmupUtils/pointers/displayedOnlyComponentsPointers',
    'warmupUtils/pointers/generalPointers',
    'warmupUtils/pointers/platformPointers',
    'warmupUtils/pointers/activeModesPointers',
    'warmupUtils/pointers/layoutPointers',
    'warmupUtils/pointers/pagePointers',
    'warmupUtils/pointers/compBehavioursPointers',
    'warmupUtils/pointers/wixappsPointers',
    'warmupUtils/pointers/multilingualPointers',
    'warmupUtils/pointers/referredStructurePointers',
    'warmupUtils/pointers/partnersPointers'
], function (pointerGeneratorsRegistry, PointersCache, DataAccessPointers) {
    'use strict';

    return {
        pointerGeneratorsRegistry,
        PointersCache,
        DataAccessPointers
    };
});
