define([
    'lodash',
    'warmupUtils/siteData/featuresDataRefs.json'
], function (
    _,
    featuresDataRefs
) {
    'use strict';

    return _.mapValues(featuresDataRefs, 'name');
});
