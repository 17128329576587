define([
    'lodash'
], function (
    _
) {
    'use strict';

    let fedopsLogger = null;
    let raven = null;

    const INTERACTIONS = {
        SAVE_BEFORE_PUBLISH: 'save-before-publish',
        SAVE_BEFORE_PUBLISH_RC: 'save-before-publish-rc',
        AUTOSAVE: {
            PRESAVE_OPERATIONS: 'autosave-presave-operations',
            SEND_AJAX: 'autosave-send-ajax',
            CALC_DIFF: 'autosave-calc-diff',
            LOAD_AJAX: 'autosave-load-ajax',
            LOAD_APPLY: 'autosave-load-apply'
        },
        SAVE: {
            FIRST_PRESAVE_OPERATIONS: 'first-save-presave-operations',
            PRESAVE_OPERATIONS: 'save-presave-operations'
        },
        USER_FILE_UPLOAD: {
            START: 'user-file-upload-start',
            END: 'user-file-upload-end'
        },
        NAVIGATE_TO_PAGE: 'navigate_to_page',
        DATA_FIXED_PAGE_REQUEST: 'data-fixed-page-request',
        PAGE: {
            INITIALIZE: 'document-services-initialize-pages'
        },
        DOCUMENT_SERVICES_INITIALIZATION: 'document-services-initialization',
        DOCUMENT_SERVICES_DATA_FIXER: 'document-services-data-fixer',
        DOCUMENT_SERVICES_DATA_FIXERS: {
            ALL: 'all-document-services-data-fixers',
            BADLY_DUPLICATED_PAGES: 'badly-duplicated-pages-fixer',
            FIVE_GRID_LINE_FULL_WIDTH: 'five-grid-line-full-width-fixer',
            ADI_GROUP: 'adi-group-fixer',
            BROKEN_ANCHORS: 'broken-anchors-fixer',
            DESIGN_DUPLICATED_IDS: 'design-duplicate-ids-fixer',
            ORPHAN_SLIDER: 'orphan-slider-fixer',
            EMPTY_MOBILE_STRIP_COLUMN_CONTAINER: 'empty-mobile-strip-column-container-fixer',
            SITE_MEMBERS: 'site-members-fixer',
            TEXT_COMPONENET: 'text-component-fixer',
            VECTOR_IMAGE: 'vector-image-fixer',
            VISUAL_FOCUS: 'visual-focus-fixer',
            MOBILE_ANIMATION: 'mobile-animation-fixer',
            STYLES: 'styles-fixer',
            DUPLICATE_ITEMS_REF_IN_MENU: 'duplicate-items-refs-in-menu-fixer',
            SOAP_ORDER: 'soap-order-fixer',
            MESH_LAYOUT_READY_SITE: 'mesh-layout-ready-site-fixer',
            ROUTERS: 'routers-fixer',
            SOSP: 'sosp-fixer',
            COMPONENT_SKIN: 'component-skin-fixer',
            MENU_CONTAINER: 'menu-container-fixer',
            ADD_MISSING_PAGE_STYLE: 'add-missing-page-style-fixer'
        },

        REGISTER_COMPONENT_TYPE: 'register-component-type',
        APP_MARKET_SERVICE: {
            CALL_SERVER: 'app-market-call-server'
        },
        MEMBERS: {
            SOCIAL_APP_LOGIN: 'members-social-app-login'
        },
        MESH_LAYOUT_READY_DATA_FIXER: 'mesh-layout-ready-data-fixer',
        COMPONENT_SKIN_DATA_FIXER: 'component-skin-data-fixer',
        CALCULATE_SITE_META_DATA_RENDER_HINTS: 'calculate-site-meta-data-render-hints',
        MESH_SITE_MIGRATION: 'mesh-site-migration'
    };

    return {
        init(siteModel) {
            raven = _.get(siteModel, 'Raven');
            this.create();
        },
        create() {
            requirejs(['fedops-logger'], function (logger) {
                fedopsLogger = _.invoke(logger, 'create', 'santa');
            });
        },
        interactionStarted(interactionName) {
            if (raven && raven.captureBreadcrumb) {
                raven.captureBreadcrumb({
                    category: 'interaction start',
                    message: `interaction ${interactionName} started`
                });
            }
            _.invoke(fedopsLogger, 'interactionStarted', interactionName);
        },

        interactionEnded(interactionName) {
            if (raven && raven.captureBreadcrumb) {
                raven.captureBreadcrumb({
                    category: 'interaction end',
                    message: `interaction ${interactionName} ended`
                });
            }
            _.invoke(fedopsLogger, 'interactionEnded', interactionName);
        },

        breadcrumb(message, additionalData) {
            if (raven && raven.captureBreadcrumb) {
                raven.captureBreadcrumb({
                    message,
                    additionalData
                });
            }
        },

        captureError(error, additionalData) {
            if (raven && raven.captureException) {
                raven.captureException(error, additionalData);
            }
        },

        appLoaded() {
            fedopsLogger.appLoaded();
        },

        INTERACTIONS
    };
});
