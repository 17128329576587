define(['lodash',
    'warmupUtilsLib',
    'warmupUtils/loggingUtils/bi/wixBI',
    'warmupUtils/loggingUtils/logger/performance',
    'warmupUtils/loggingUtils/logger/performanceMetrics',
    'warmupUtils/loggingUtils/logger/services/browsingSession',
    'experiment'
], function (_, warmupUtilsLib, wixBI, performance, performanceMetrics, browsingSession, experiment) {
    'use strict';

    const {MARK_NAMES, MEASURE_NAMES} = performanceMetrics;
    const {constants} = warmupUtilsLib;
    const already = [];
    const {wixBiSession = {}} = typeof window !== 'undefined' ? window : {};

    const beatData = {};

    const BEAT_SITE_TYPES = ['No Site Type', 'WixSite', 'UGC', 'Template'];
    const BEAT_EVENT_TYPES = ['No Event Type', 'start', 'visible', 'finish'];
    const UNSAMPLED_EVENT_TYPES = [1, 2, 3, 4, 12, 13];

    const baseOptions = {
        adapter: 'bt',
        biUrl: 'http://frog.wix.com/'
    };
    const beatEventDefinition = {
        src: 29,
        evid: 3
    };
    const lastPositionedProperties = ['url', 'ref'];

    const trackBrowsingSession = eventType => eventType === 'finish' ? browsingSession.track() : browsingSession.get();

    function getSampleRatio(eventType) {
        if (_.includes(UNSAMPLED_EVENT_TYPES, eventType)) {
            return 1; // 100%
        }

        return 100; // 1%
    }

    function biBool(bool) {
        return bool ? '1' : '0';
    }

    function genBeatName(eventType, withLabel) {
        const eventNumber = _.indexOf([1, 'visible', 'finish'], eventType) + 1 || eventType;
        const markName = MARK_NAMES[eventNumber];
        if (!withLabel) {
            return markName;
        }
        const label = `beat ${eventNumber}`;
        return markName ? `${markName} (${label})` : label;
    }

    function paramsToQueryString(params) {
        // booleans should be converted to 1/0
        const query = warmupUtilsLib.urlUtils.toQueryString(_.omit(params, lastPositionedProperties), true);
        return _.reduce(lastPositionedProperties, function (result, prop) {
            return `${result}&${warmupUtilsLib.urlUtils.toQueryParam(prop, params[prop], true)}`;
        }, query);
    }
    function buildBeatParams(siteData, reportDefinition, eventType, pageId) {
        const sessionParams = getBeatSessionParams(siteData);
        const eventParams = getBeatEventParams(siteData, eventType, pageId);
        const params = {
            et: getEventType(eventType),
            is_rollout: _.get(siteData, 'wixBiSession.is_rollout', 0),
            is_platform_loaded: Number(_.get(siteData, 'ssr.afterWixCode', false)),
            ispp: Number(_.get(siteData.getMasterPageStyleSettings(), 'stylesPerPage') === constants.STYLES_PER_PAGE_VER)
        };
        const isCached = _.get(siteData, 'wixBiSession.isCached');
        if (typeof isCached !== 'undefined') {
            params.is_cached = Number(isCached);
        }
        const renderType = _.get(siteData, 'wixBiSession.renderType');
        if (renderType) {
            params.viewer_name = renderType;
        }
        const eventName = genBeatName(eventType, false);
        if (eventName) {
            params.event_name = eventName;
        }
        return _.merge(params, reportDefinition, sessionParams, eventParams);
    }
    function getBeatSessionParams(siteData) {
        const {wixBiSession: wbs} = siteData;
        return {
            vuuid: getVisitorUuid(),
            vid: wbs.visitorId,
            mid: wbs.siteMemberId,
            dc: wbs.dc || '',
            vsi: beatData[siteData.siteId].viewerSessionId,
            uuid: siteData.siteHeader.userId,
            sid: siteData.siteId,
            iss: _.invoke(siteData, 'isClientAfterSSR', false),
            msid: siteData.getMetaSiteId(),
            rid: wbs.requestId
        };
    }
    function getBeatEventParams(siteData, eventType, pageId) { // eslint-disable-line complexity
        const {biData, wixBiSession: wbs} = siteData;
        const time = biData.getTime();
        const session = trackBrowsingSession(eventType, biData);
        const params = {
            pid: pageId,
            pn: biData.getPageNumber(),
            st: getSiteType(siteData),
            sr: getDesktopSize(),
            wr: getWindowSize(),
            isjp: biBool(wbs.maybeBot),
            isp: siteData.isPremiumDomain(),
            url: urlWithoutWWW(siteData.currentUrl.full),
            ref: window.document.referrer,
            ts: eventType === 'start' ? 0 : time.loadingTime,
            tts: eventType === 'start' ? 0 : time.totalLoadingTime,
            c: _.now(),
            v: siteData.baseVersion || 'unknown',
            fis: session.isNew,	
            bsi: session.id
        };
        if (eventType === 'finish' && typeof window !== 'undefined' && window.santaRenderingError) {
            params.ssr_fb = window.santaRenderingError.reason;
        }
        if (wbs) {
            if (wbs.checkVisibility) {
                params.ita = wbs.checkVisibility();
            }
            if ('usingPlatform' in wbs) {
                params.is_platform_loaded = biBool(wbs.usingPlatform);
            }
        }
        return params;
    }
    function getSiteType(siteData) {
        const documentType = siteData.rendererModel.siteInfo.documentType;
        const siteType = _.indexOf(BEAT_SITE_TYPES, documentType);
        return siteType !== -1 ? siteType : documentType;
    }
    function getVisitorUuid() {
        let vuuid = warmupUtilsLib.cookieUtils.getCookie('_wixUIDX') || '';
        vuuid = vuuid.slice(_.lastIndexOf(vuuid, '|') + 1); //remove anything before any pipe, including the pipe.
        vuuid = vuuid.replace(/^(null-user-id|null)$/g, ''); //replace invalid values with empty string.
        return vuuid;
    }
    function getEventType(eventType) {
        const index = _.indexOf(BEAT_EVENT_TYPES, eventType);
        if (index !== -1) {
            return index;
        }
        return eventType > 3 ? eventType : -1;
    }
    function urlWithoutWWW(url) {
        return url.replace(/^http(s)?:\/\/(www\.)?/, '').substring(0, 256);
    }
    function getDesktopSize() {
        if (typeof window === 'undefined') {
            return '';
        }
        const width = window.screen && window.screen.width || 0; // eslint-disable-line no-mixed-operators
        const height = window.screen && window.screen.height || 0; // eslint-disable-line no-mixed-operators
        return `${width}x${height}`;
    }
    function getWindowSize() { // eslint-disable-line complexity
        if (typeof window === 'undefined') {
            return '';
        }
        let width = 0;
        let height = 0;
        if (window.innerWidth) {
            width = window.innerWidth;
            height = window.innerHeight;
        } else if (window.document) {
            if (window.document.documentElement && window.document.documentElement.clientWidth) {
                width = window.document.documentElement.clientWidth;
                height = window.document.documentElement.clientHeight;
            } else if (window.document.body && window.document.body.clientWidth) {
                width = window.document.body.clientWidth;
                height = window.document.body.clientHeight;
            }
        }
        return `${width}x${height}`;
    }

    function updateBiSession(siteData, {et}) {
        wixBiSession.et = et;
        siteData.wixBiSession.et = et;
    }

    const isHealthBeat = eventType => eventType > 3 && eventType !== 16;

    function isHealthBeatAlreadySent(eventType) {
        const prev = already[eventType];
        already[eventType] = true;
        return prev;
    }

    function canSend(siteData, eventType) {
        return siteData &&
            siteData.wixBiSession.viewerSessionId &&
            siteData.viewMode !== 'preview' &&
            !warmupUtilsLib.stringUtils.isTrue(siteData.currentUrl.query.suppressbi) &&
            getEventType(eventType) !== -1 &&
            (!isHealthBeat(eventType) || !isHealthBeatAlreadySent(eventType));
    }

    function initBeatData(siteData) {
        beatData[siteData.siteId] = beatData[siteData.siteId] || {
            viewerSessionId: siteData.wixBiSession.viewerSessionId || warmupUtilsLib.guidUtils.getGUID() // FIXME(ssr-guid) - needs to be taken from siteData
        };
    }

    function buildBiParams(siteData, beatParams) {
        const biParams = {
            queryString: paramsToQueryString(beatParams),
            adapter: baseOptions.adapter,
            biUrl: siteData.getServiceTopologyProperty('biServerUrl') || baseOptions.biUrl
        };
        return biParams;
    }

    function isInSample(siteData, sampleRatio) {
        const {wixBiSession: wbs} = siteData;

        if (!wbs.viewerSessionId) {
            return Math.floor(wixBiSession.random * sampleRatio) === 0;
        }

        if (_.isUndefined(wbs.coin)) {
            wbs.coin = parseInt(wbs.viewerSessionId, 16);
        }

        let res = wbs.coin % sampleRatio === 0;

        if (!res && _.isString(wbs.requestId) && experiment.isOpen('biSampleByRequestId', siteData)) {
            const coin = parseInt(wbs.requestId, 10);
            res = coin % sampleRatio === 0;
        }

        return res;
    }


    function shouldIncludeInSampleRatio(siteData, sampleRatio) {
        const sampleRatioState = siteData.currentUrl.query.sampleratio;
        if (_.result(siteData, 'isDebugMode', false) && sampleRatioState !== 'force' || sampleRatioState === 'none' || !sampleRatio) { // eslint-disable-line no-mixed-operators
            return true;
        }

        return isInSample(siteData, sampleRatio);
    }
    function reportBiEvent(siteData, beatParams) {
        if (_.includes(UNSAMPLED_EVENT_TYPES, beatParams.et) || shouldIncludeInSampleRatio(siteData, getSampleRatio(beatParams.et))) {
            const biParams = buildBiParams(siteData, beatParams);
            wixBI.report(siteData, biParams);
        }
    }

    function getMeasureName({viewMode}, eventType) {
        const measureName = MEASURE_NAMES[eventType] || eventType;
        return !viewMode || viewMode === 'site' ? measureName : `${measureName} ${viewMode}`;
    }

    let prevMark; // eslint-disable-line santa/no-module-state
    function reportBeatEvent(siteData, eventType, pageId) {
        initBeatData(siteData);
        const beatParams = buildBeatParams(siteData, beatEventDefinition, eventType, pageId);
        updateBiSession(siteData, beatParams);

        if (canSend(siteData, eventType)) {
            reportBiEvent(siteData, beatParams);
        }

        if (!siteData.isViewerMode()) {
            return;
        }

        if (!prevMark) {
            prevMark = genBeatName(5, true);
            try {
                performance.measure(getMeasureName(siteData, 0), 'fetchStart', 'domLoading', true);
                const beat4 = genBeatName(4, true);
                performance.measure(getMeasureName(siteData, 4), 'domLoading', beat4, true);
                performance.measure(getMeasureName(siteData, 5), beat4, prevMark, true);
            } catch (e) {
                // failed `performance.measure` call shouldn't crash the app
                console.warn(e); // eslint-disable-line no-console
            }
        }

        const mark = genBeatName(eventType, true);
        performance.mark(mark);
        try {
            performance.measure(getMeasureName(siteData, eventType), prevMark, mark, true);
        } catch (e) {
            // failed `performance.measure` call shouldn't crash the app
            console.warn(e); // eslint-disable-line no-console
        }
        prevMark = mark;
    }

    /**
     *
     * @type {{reportBeatEvent: reportBeatEvent}}
     */
    return {
        reportBeatEvent,
        shouldIncludeInSampleRatio
    };
});
