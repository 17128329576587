define([
    'lodash',
    'warmupUtils/loggingUtils/logger/logger',
    'warmupUtils/loggingUtils/logger/performance',
    'warmupUtils/tpa/bi/events',
    'warmupUtils/tpa/services/tpaDataService'
], function (
    _,
    logger,
    performance,
    events,
    tpaDataService
) {
    'use strict';

    const TPA_COMP_TYPES = {
        TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
        TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        TPA_GLUED_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        TPA_WORKER: 'tpa.viewer.classes.TPAWorker'
    };

    function getComponentFromStructure(siteData, compId, pageId) {
        const pageData = siteData.getPageData(pageId) || _.get(siteData, ['pagesData', pageId]);
        const pageStructure = _.get(pageData, ['structure', siteData.getViewMode()]);
        return _.get(pageStructure, compId);
    }

    function getComponentFromCurrentAndMasterPage(siteData, compId) {
        const currentPageId = siteData.getCurrentUrlPageId();
        return getComponentFromStructure(siteData, compId, currentPageId) || getComponentFromStructure(siteData, compId, siteData.MASTER_PAGE_ID);
    }

    function isTPAComp(siteData, compId) {
        if (compId) {
            const comp = getComponentFromCurrentAndMasterPage(siteData, compId);
            if (comp) {
                return _.includes(TPA_COMP_TYPES, comp.componentType);
            }
        }
        return false;
    }

    function checkIsInSSR({tpasRenderedInSsr}, compId) {
        return (tpasRenderedInSsr && tpasRenderedInSsr[compId]) === true;
    }

    function reportPerformanceBiEvent(eventDescriptor, reportBiFunc, biData, compId, isInSSR, {isWixTPA, appDefinitionId} = {}, widgetId, params) {
        const time = biData.getTime();

        const eventParams = {
            compId,
            pageId: biData.getPageId(),
            pageNo: biData.getPageNumber(),
            ssr: isInSSR,
            loadingTime: time.loadingTime,
            totalLoadingTime: time.totalLoadingTime
        };

        if (isWixTPA) {
            eventParams.widgetId = widgetId;
            eventParams.appDefinitionId = appDefinitionId;
        } else if (!(params && params.widgetId && params.appDefinitionId)) {
            eventParams.externalWidgetId = widgetId;
            eventParams.externalAppDefinitionId = appDefinitionId;
        }

        _.merge(eventParams, params);
        reportBiFunc(eventDescriptor, eventParams);
    }

    function reportWarmupPerformanceBiEvent(eventDescriptor, siteData, compId, params) {
        let widgetId, appData;
        const comp = getComponentFromCurrentAndMasterPage(siteData, compId);
        if (comp) {
            const compData = siteData.getDataByQuery(comp.dataQuery);
            appData = siteData.rendererModel.clientSpecMap[compData && compData.applicationId];
            widgetId = compData.widgetId;
        }
        const reportBiFunc = _.partial(logger.reportBI, siteData);
        const isInSSR = checkIsInSSR(siteData, compId);
        reportPerformanceBiEvent(eventDescriptor, reportBiFunc, siteData.biData, compId, isInSSR, appData, widgetId, params);
        if (eventDescriptor.eventId === events.APP_LOADED_SUCCESSFULLY.eventId) {
            performance.finish(`Section ${compId}`, {category: 'sections', retainMarks: true});
        }
    }

    // eslint-disable-next-line complexity
    function isTpaSupportedInWarmup(siteData, compId) {
        if (!compId) {
            return false;
        }
        if (checkIsInSSR(siteData, compId)) {
            return true;
        }
        return false;
    }

    function getTemplateValuesFromUrl(url) {
        const TEMPLATE_REG_EXP = /\${(.*?)}/g;
        const templates = [];
        let match = TEMPLATE_REG_EXP.exec(url);
        while (match) {
            templates.push(match[1]);
            match = TEMPLATE_REG_EXP.exec(url);
        }
        return templates;
    }

    return {
        tpaDataService,
        events,
        isTPAComp,
        reportPerformanceBiEvent,
        reportWarmupPerformanceBiEvent,
        isTpaSupportedInWarmup,
        getComponentFromCurrentAndMasterPage,
        getTemplateValuesFromUrl
    };
});
